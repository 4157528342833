import { RestrictedScreen } from "@screens/Restricted";
import React from "react";

export async function getStaticProps() {
    return {
        props: {
            layout: "empty",
        },
    };
}

export default function Restricted(props: any) {
    return <RestrictedScreen {...props} />;
}
