import { Box } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useTheme } from "styled-components/native";

export const RestrictedScreen = () => {
    const theme = useTheme();
    const searchParams = useSearchParams();
    const type = searchParams.get("type");

    return (
        <Box
            flex={1}
            justify="center"
            align="center"
            gap={theme.spacing.bigger}
        >
            <Text huge bold>
                Restricted Access
            </Text>

            <Text
                small
                style={{ lineHeight: theme.text.biggest }}
                textAlign="center"
                color={theme.color.rgba(theme.color.white, 0.7)}
            >
                {`Your ${
                    type && type === "wallet" ? "wallet address" : "country"
                } is restricted from accessing this website due to legal reasons.`}
            </Text>
        </Box>
    );
};
